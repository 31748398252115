import React from 'react';
import { useLocation } from 'react-router-dom'; // Importeer useLocation
import img from '../../assets/img/background_handtekening.png';
import './NoPage.css';  // Vergeet niet de CSS te importeren

export default function NoPage() {
    const location = useLocation(); // Haal de huidige locatie op

    const handleClick = () => {
        window.location.href = '/'; // Hier kun je de URL aanpassen naar waar je wilt navigeren
    };

    return (
        <div className="no-page-container">
            <img src={img} alt="Handtekening" />
            <h2>De pagina niet gevonden</h2>
            <h3>roemus.nl{location.pathname}</h3> {/* Weergave van de huidige URL */}
            <div className="Home-button" onClick={handleClick}> {/* Gebruik className */}
                <span className="Popup">roemus.nl</span>
                <span>
                    Home
                </span>
            </div>
        </div>
    );
}
