import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartPage from './views/StartPage/StartPage'; // Import your StartPage component
import HomePage from './views/HomePage/HomePage'; // Import your HomePage component
import NoPage from './views/NoPage/NoPage'; // Import your HomePage component


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
  );
}

export default App;
