import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faPhone, faCode,faAddressCard } from '@fortawesome/free-solid-svg-icons';
import DarkModeToggle from '../../components/DarkModeToggle/DarkModeToggle';
import './NavBar.css';

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="navbar">
      <nav aria-label="Main Navigation">

        {/* Sidebar for desktop */}
        <div className="sidebar">
          <a href="#home" className="nav-item" onClick={closeMenu}>
            <span className="icon"><FontAwesomeIcon icon={faHouse} /></span>
            <span className="nav-label">Home</span>
          </a>
          <a href="#about" className="nav-item" onClick={closeMenu}>
            <span className="icon"><FontAwesomeIcon icon={faAddressCard} /></span>
            <span className="nav-label">About</span>
          </a>
          <a href="#services" className="nav-item" onClick={closeMenu}>
            <span className="icon"><FontAwesomeIcon icon={faCode} /></span>
            <span className="nav-label">Services</span>
          </a>
          <a href="#contact" className="nav-item" onClick={closeMenu}>
            <span className="icon"><FontAwesomeIcon icon={faPhone} /></span>
            <span className="nav-label">Contact</span>
          </a>
          <DarkModeToggle />
        </div>

        {/* Hamburger menu for mobile */}
        <div className="toggle-menu" onClick={toggleMenu}>
          <div className={`menu-btn ${isMenuOpen ? 'open' : ''}`}>
            <div className="menu-btn__burger"></div>
          </div>
        </div>

        {/* Full-screen blur overlay for mobile */}
        {isMenuOpen && <div className="blur-overlay" onClick={toggleMenu}></div>}

        {/* Mobile menu */}
        <div className={`main-menu ${isMenuOpen ? 'show' : ''}`}>
          <a href="#home" className="nav-item" onClick={closeMenu}>Home</a>
          <a href="#about" className="nav-item" onClick={closeMenu}>About</a>
          <a href="#services" className="nav-item" onClick={closeMenu}>Services</a>
          <a href="#contact" className="nav-item" onClick={closeMenu}>Contact</a>
          <DarkModeToggle />
        </div>
      </nav>
    </header>
  );
}

export default NavBar;
