import "./StartPage.css"
function StartPage() {
    return (
        <div>

            <a href="webgame\index.html">
                <section id="chat">
                    <div class="bg"></div>
                    <div class="content">
                        <h1 class="site-name">Game</h1>
                    </div>
                </section>
            </a>

            <a href="/home">
                <section id="forum">
                    <div class="bg"></div> 
                    <div class="content">
                        <h1 class="site-name">Website</h1>
                    </div>
                </section>
            </a>

        </div>
    );
}

export default StartPage;
